import React from 'react'
import {
  Switch, Route, Redirect
} from 'react-router-dom'

import ProfileAvatarSelection from './screens/01-profile-avatar-selection'
import { links } from './constants'
import ContentSelection from './screens/02-content-selection'

const Router = () => {
  return (
    <Switch>
      <Route path={links.profileSelection} component={ProfileAvatarSelection} />
      <Route path={links.contentSelection} component={ContentSelection} />

      <Redirect to="/" />
    </Switch>
  )
}

export default Router
