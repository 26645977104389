import React from 'react'
import PropTypes from 'prop-types'

import { useStyles } from './content-grid-item.styles'
import VectorIcon from '../../../icons/vector-outline'

export const SelectionOverlay = ({ isSelected }) => {
  const styles = useStyles()
  return isSelected ? (
    <div className={styles.selectedOverlay}>
      <VectorIcon className={styles.icon} />
    </div>
  ) : null
}

SelectionOverlay.propTypes = {
  isSelected: PropTypes.bool
}
