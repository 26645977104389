import React from 'react'

const VectorIcon = props => {
  return (
    <svg width="71" height="71" viewBox="0 0 71 71" fill="none" {...props}>
      <path
        d="M22.4766 46.25H27.461V31.25H22.4766V46.25ZM49.8909 32.5C49.8909 31.125 48.7694 30 47.3987 30H39.5358L40.7196 24.2875L40.7569 23.8875C40.7569 23.375 40.5451 22.9 40.2086 22.5625L38.8878 21.25L30.6884 29.4875C30.2273 29.9375 29.9532 30.5625 29.9532 31.25V43.75C29.9532 45.125 31.0747 46.25 32.4454 46.25H43.6604C44.6946 46.25 45.5794 45.625 45.9532 44.725L49.7164 35.9125C49.8286 35.625 49.8909 35.325 49.8909 35V32.5Z"
        fill="#CAFD34"
      />
      <path
        d="M69.4724 35.4688C69.4724 54.061 54.4486 69.125 35.9256 69.125C17.4027 69.125 2.37891 54.061 2.37891 35.4688C2.37891 16.8765 17.4027 1.8125 35.9256 1.8125C54.4486 1.8125 69.4724 16.8765 69.4724 35.4688Z"
        stroke="#CAFD34"
        strokeWidth="3"
      />
    </svg>
  )
}

export default VectorIcon
