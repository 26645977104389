import React, { useContext } from 'react'
import { UseItemAnimationContext } from '../../../hooks/use-item-animation'
import { useProfile } from '../../../hooks/use-profile'
import ContentGrid from '../content-grid/content-grid'
import LoadingContent from '../loading-content/loading-content'
import { useStyles } from './selection-content.styles'

const SelectionContent = () => {
  const styles = useStyles()
  const { accountName } = useProfile()
  const [{ loading }] = useContext(UseItemAnimationContext)

  if (loading) {
    return <LoadingContent accountName={accountName} />
  }

  return (
    <>
      <div className={styles.title}>
        Hey
        {' '}
        {accountName}
        , tell us what you&apos;re interested in
      </div>
      <div className={styles.subTitle}>
        It will help us find TV shows and movies you’ll love. Please pick at
        least 3 that you like.
      </div>

      <ContentGrid />
    </>
  )
}

export default SelectionContent
