import React from 'react'
import PropTypes from 'prop-types'

import { useStyles } from './content-grid-item.styles'

export const HoverOverlay = ({ isSelected, isHovered }) => {
  const styles = useStyles()
  return !isSelected && isHovered ? (
    <div className={styles.hoverOverlay} />
  ) : null
}

HoverOverlay.propTypes = {
  isHovered: PropTypes.bool,
  isSelected: PropTypes.bool
}
