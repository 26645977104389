import React, { useState } from 'react'
import classnames from 'classnames'
import ContentGridItem from '../content-grid-item/content-grid-item'
import { useStyles } from './content-grid.styles'
import { useContentSelection } from '../../../hooks/use-content-selection'

const ContentGrid = () => {
  const {
    contents, confirmHandler, loading
  } = useContentSelection()

  const styles = useStyles()

  const [selectedList, setSelectedList] = useState([])

  const selectHandler = id => {
    const isExisted = selectedList.indexOf(id) > -1

    if (isExisted) {
      const filterList = selectedList.filter(item => item !== id)
      setSelectedList(filterList)
      return
    }
    const updateList = selectedList.concat(id)
    setSelectedList(updateList)
  }

  if (loading) {
    return <div>Loading</div>
  }

  const buttonText = () => {
    if (selectedList.length === 0) {
      return 'Pick 3 shows'
    }

    if (selectedList.length < 3) {
      return `Pick ${3 - selectedList.length} more`
    }

    return 'Continue'
  }

  return (
    <div className={styles.container}>
      <div className={styles.grid}>
        {contents.map(item => {
          return (
            <ContentGridItem
              key={item.contentItemId}
              contentItemId={item.contentItemId}
              imageUri={item.image.uri}
              onSelected={contentItemId => selectHandler(contentItemId)}
              isSelected={selectedList.indexOf(item.contentItemId) > -1}
            />
          )
        })}
      </div>
      <button
        disabled={selectedList.length < 3}
        className={classnames(styles.primaryButton, styles.button)}
        onClick={() => confirmHandler(selectedList)}
      >
        {buttonText()}
      </button>
    </div>
  )
}

export default ContentGrid
