import React, { createContext, useReducer } from 'react'

/**
 * A hooks to set grid item animation
 */
export const FADE_OUT = 'FADE_OUT'
export const LOADING = 'LOADING'

const reducer = (state, action) => {
  switch (action.type) {
    case FADE_OUT:
      return {
        ...state,
        fadeOut: action.fadeOut
      }
    case LOADING:
      return {
        ...state,
        loading: action.loading
      }
    default:
      return state
  }
}

const initialState = { fadeOut: false, loading: false }

export const UseItemAnimationContext = createContext(initialState)

const UseItemAnimationProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  return (
    <UseItemAnimationContext.Provider value={[state, dispatch]}>
      {children}
    </UseItemAnimationContext.Provider>
  )
}

export default UseItemAnimationProvider
