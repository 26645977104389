import { useContext, useEffect, useState } from 'react'
import { useQuery, useMutation, useLazyQuery } from '@apollo/react-hooks'
import { useHistory } from 'react-router-dom'
import COLLECTION_QUERY from '../graphql/collection.gql'
import ADD_SELECTIONS_MUTATION from '../graphql/add-on-boarding-selections.gql'
import ON_BOARDING_STATUS from '../graphql/on-boarding-status.gql'
import {
  UseItemAnimationContext,
  FADE_OUT,
  LOADING
} from './use-item-animation'

const collectionId = 'c02cdef3-2167-4aa3-ad56-07cb61ccb33d'

/**
 * Handle logic to display content selections, and after selecting
 */
export const useContentSelection = () => {
  const [, dispatch] = useContext(UseItemAnimationContext)

  // collection tiles
  const [collection, setCollection] = useState([])

  // State for checking if waiting for recommendation result
  const [isPending, setIsPending] = useState(false)

  const history = useHistory()

  const {
    data: collectionData,
    loading: collectionLoading,
    error: collectionError
  } = useQuery(COLLECTION_QUERY, {
    variables: {
      collectionId
    }
  })

  useEffect(() => {
    if (!collectionLoading && collectionData) {
      collectionData &&
        collectionData.collection &&
        collectionData.collection.tiles.length > 0 &&
        setCollection(collectionData.collection.tiles)
    }
  }, [collectionData, collectionLoading, collectionError])

  const [
    addSelections,
    {
      data: addSelectionsData,
      error: addSelectionsError,
      loading: addSelectionsLoading
    }
  ] = useMutation(ADD_SELECTIONS_MUTATION)

  useEffect(() => {
    if (addSelectionsLoading) {
      setIsPending(true)
    }

    if (addSelectionsData && !addSelectionsLoading) {
      setIsPending(addSelectionsData.addOnboardSelections.isPending)
    }
  }, [addSelectionsData, addSelectionsError, addSelectionsLoading])

  // Change animation loading state
  // it will check onBoardStatus within 5 sec
  // within 5 sec, once status meets the requirement, will direct to homepage
  // after 5 sec, will direct to homepage anyway
  useEffect(() => {
    if (isPending) {
      // 1 second delay for fading out selected items
      const timerForLoading = setTimeout(() => {
        dispatch({ type: LOADING, loading: true })

        let counter = 0
        const timer = setInterval(() => {
          counter += 1
          getBoardStatus()

          if (counter > 5) {
            clearInterval(timer)
            clearTimeout(timerForLoading)
            history.push('/')
          }
        }, 1000)
      }, 1000)
    }
  }, [isPending])

  const [
    getBoardStatus,
    {
      data: onBoardingStatus,
      loading: onBoardingLoading,
      error: onBoardingError
    }
  ] = useLazyQuery(ON_BOARDING_STATUS)

  useEffect(() => {
    if (onBoardingError) {
      history.push('/')
    }

    if (onBoardingStatus && !onBoardingLoading) {
      if (
        onBoardingStatus.onboardStatus.status === 'COMPLETED' ||
        onBoardingStatus.onboardStatus.status === 'ERROR'
      ) {
        history.push('/')
      }
    }
  }, [onBoardingStatus, onBoardingLoading, onBoardingError])

  const confirmHandler = selectedList => {
    addSelections({ variables: { selections: selectedList } })
    dispatch({ type: FADE_OUT, fadeOut: true })
  }

  return {
    contents: collection,
    confirmHandler,
    loading: collectionLoading
  }
}
