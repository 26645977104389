import React from 'react'
import SelectionContent from '../components/content-selection/selection-content/selection-content'

const ContentSelection = () => {
  return (
    <>
      <SelectionContent />
    </>
  )
}

export default ContentSelection
