import React from 'react'
import PropTypes from 'prop-types'

import Loading from '../../../icons/loading'
import { useStyles } from './loading-content.styles'
import animateStyles from './loading-content.css'

const LoadingContent = props => {
  const styles = useStyles()

  return (
    <div className={animateStyles.loadingContentContainer}>
      <div className={styles.title}>
        Personalising for
        {' '}
        {props.accountName}
      </div>
      <div className={styles.subTitle}>
        We&apos;re looking for TV shows and movies we think you &apos;ll like.
      </div>
      <div className={styles.loadingContainer}>
        <Loading />
      </div>
    </div>
  )
}

LoadingContent.propTypes = {
  accountName: PropTypes.string.isRequired
}
export default LoadingContent
