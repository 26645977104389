import { createUseStyles } from 'react-jss'

export const useStyles = createUseStyles(theme => ({
  title: {
    fontSize: 36,
    lineHeight: 1.08,
    fontWeight: 500,
    letterSpacing: 'normal',
    textAlign: 'center',
    margin: [15, 0],
    [theme.media.mobile]: {
      margin: [10, 0],
      fontSize: 30
    }
  },

  subTitle: {
    fontSize: 20,
    lineHeight: 0.95,
    fontWeight: 400,
    letterSpacing: 'normal',
    textAlign: 'center',
    marginTop: 0,
    [theme.media.mobile]: {
      fontSize: 14,
      lineHeight: '18px'
    }
  }
}))
