export const links = {
  profileSelection: '/content-onboarding/profile-selection',
  contentSelection: '/content-onboarding/content-selection'
}

function calcRatio(x, y) {
  return y / x
}

export const recipe = {
  width: 240,
  ratio: calcRatio(4, 3)
}
