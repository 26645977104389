import React from 'react'
import { useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import Header from '../header'
import AsyncNavigationFooter from '../../../../containers/navigation/async-navigation-footer'
import UseNotFoundProvider from '../../../../components/not-found/hooks/use-not-found-context'
import UseItemAnimationProvider from '../../hooks/use-item-animation'

import { useStyles } from './layout.styles'
import { setTheme } from '../../../../actions'
import { THEME_OPTIONS } from '../../../../constants'

const Layout = ({ children }) => {
  const dispatch = useDispatch()
  dispatch(setTheme(THEME_OPTIONS.dark))

  const styles = useStyles()
  const location = useLocation()

  return (
    <UseNotFoundProvider>
      <UseItemAnimationProvider>
        <div className={styles.layout}>
          <Header />

          <div className={styles.content}>{children}</div>

          <AsyncNavigationFooter
            location={location}
            className={styles.footer}
          />
        </div>
      </UseItemAnimationProvider>
    </UseNotFoundProvider>
  )
}

export default Layout
