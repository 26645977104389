import { useEffect } from 'react'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { useHistory } from 'react-router-dom'
import GET_PROFILE from '../graphql/get-profile.gql'
import UPDATE_PROFILE from '../graphql/update-profile.gql'
import { MODALS } from '../../../constants'
import { getModalLocation } from '../../../lib/modal'
import { links } from '../constants'

export const useProfile = () => {
  const [
    updateProfile,
    { data: updatedProfileData, loading: updateLoading, error: updateError }
  ] = useMutation(UPDATE_PROFILE)

  const {
    data: profileData,
    loading: profileLoading,
    error: profileError
  } = useQuery(GET_PROFILE)

  const history = useHistory()

  useEffect(() => {
    if (updatedProfileData && !updateLoading) {
      updatedProfileData.profile.selectedProfile && history.push(links.contentSelection)
    }
  }, [updatedProfileData, updateLoading, updateError])

  useEffect(() => {
    // Redirect to home page if user is old existing account who created before 7 days
    const d = new Date()
    d.setDate(d.getDate() - 7)
    if (
      profileData &&
      profileData.account &&
      profileData.account.createdAt &&
      new Date(profileData.account.createdAt) < d
    ) {
      history.push('/')
    }
    if (profileError) {
      // TODO push to home page needs to force refresh page
      // may need better solution
      history.push(getModalLocation({ pathname: '/' }, MODALS.qsParams.login))
      window.location.reload()
    }
  }, [profileData, profileLoading, profileError])

  const clickHandler = avatarId => {
    const profileId = profileData.account.profiles.filter(
      profile => profile.isDefault && !profile.isKid
    )
    const avatar = parseInt(avatarId, 10)
    updateProfile({
      variables: {
        input: {
          avatar,
          id: profileId[0].id
        }
      }
    })
  }

  return { clickHandler, accountName: profileData && profileData.account.name }
}
