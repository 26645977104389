import { useQuery } from '@apollo/react-hooks'
import CONFIG_QUERY from '../graphql/config.gql'
import getDeviceInfo from '../../../lib/device-info'

/**
 * Config query to get all avatars
 * filter out kids profile image
 */
export const useConfig = () => {
  const deviceInfo = getDeviceInfo()
  const { data, loading, error } = useQuery(CONFIG_QUERY, {
    variables: {
      input: {
        deviceInfo: {
          ...deviceInfo,
          platform: 'Web'
        }
      }
    },
    notifyOnNetworkStatusChange: true
  })

  return {
    avatars: data && data.config.avatars.filter(avatar => !avatar.isKidsDefault),
    loading,
    error
  }
}
