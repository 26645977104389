import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { useStyles } from './profile-avatar-item.styles'
import Imgix from '../../../../../components/imgix'

const ProfileAvatarItem = ({ onProfileSelect, avatar, isSelected }) => {
  const { uri, id } = avatar
  const styles = useStyles()

  const overlayClasses = classnames(styles.itemOverlay, {
    [styles.selected]: isSelected
  })

  const overlaySelect = avatarId => {
    onProfileSelect(avatarId)
  }

  return (
    <div className={overlayClasses} onClick={() => overlaySelect(id)}>
      <Imgix className={styles.avatar} alt="Avatar" src={uri} background />
    </div>
  )
}

ProfileAvatarItem.propTypes = {
  onProfileSelect: PropTypes.func,
  avatar: PropTypes.shape({
    uri: PropTypes.string,
    id: PropTypes.string,
    isDefault: PropTypes.bool,
    isKidsDefault: PropTypes.bool
  }),
  isSelected: PropTypes.bool
}

export default ProfileAvatarItem
