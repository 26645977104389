import React, { useState } from 'react'
import classnames from 'classnames'
import { useConfig } from '../../../hooks/use-config'
import ProfileAvatarItem from '../profile-avatar-item/profile-avatar-item'
import { useStyles } from './profile-avatar-selection-content.styles'
import { useProfile } from '../../../hooks/use-profile'

const ProfileAvatarSelectionContent = () => {
  const { avatars, loading, error } = useConfig()
  const styles = useStyles()
  const [selectedAvatarId, setSelectedAvatarId] = useState()

  const { clickHandler } = useProfile()

  // TODO proper loading and error display
  if (loading) {
    return <div>Loading</div>
  }
  if (error) {
    return <div>Error</div>
  }

  const onAvatarSelectHandler = (avatarId) => {
    setSelectedAvatarId(avatarId)
  }

  return (
    <div className={styles.content}>
      <div className={styles.profileList}>
        {avatars.map(avatar => {
          return (
            <ProfileAvatarItem
              key={avatar.id}
              onProfileSelect={id => onAvatarSelectHandler(id)}
              avatar={avatar}
              isSelected={avatar.id === selectedAvatarId}
            />
          )
        })}
      </div>
      <button
        disabled={!selectedAvatarId}
        onClick={() => clickHandler(selectedAvatarId)}
        className={classnames(styles.primaryButton, styles.button)}
      >
        Continue
      </button>
    </div>
  )
}

export default ProfileAvatarSelectionContent
