import React from 'react'
import ProfileAvatarSelectionContent from '../components/profile-avatar-selection/profile-avatar-selection-content/profile-avatar-selection-content'
import { useStyles } from './screen.styles'

const ProfileAvatarSelection = () => {
  const styles = useStyles()
  return (
    <>
      <div className={styles.title}>Choose your profile picture</div>
      <div className={styles.subTitle}>You can change this at anytime when you edit your profile</div>
      <ProfileAvatarSelectionContent />
    </>
  )
}

export default ProfileAvatarSelection
