import { createUseStyles } from 'react-jss'

export const useStyles = createUseStyles(theme => ({
  container: {
    background: '#1F1F1F',
    width: 'calc(20% - 10px)',
    [theme.media.mobile]: {
      width: 'calc(50% - 10px)',
      paddingTop: 4,
      paddingBottom: 4
    },
    boxSizing: 'border-box',
    marginTop: 6,
    position: 'relative'
  },

  imgix: {
    width: '100%',
    height: '100%',
    backgroundPosition: 'top',
    objectFit: 'cover',
    objectPosition: 'top'
  },

  hoverOverlay: {
    width: '100%',
    height: '100%',
    border: `${theme.neonGreen} 3px solid`,
    position: 'absolute',
    [theme.media.mobile]: {
      borderWidth: 2
    }
  },

  selectedOverlay: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(16, 16, 16, 0.3)'
  },

  icon: {
    [theme.media.mobile]: {
      width: 45
    }
  }
}))
