import { createUseStyles } from 'react-jss'
import { commonStyles } from '../../../../shared/common.styles'

export const useStyles = createUseStyles(theme => ({
  ...commonStyles(theme),

  grid: {
    maxWidth: 1400,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    flexFlow: 'row wrap'
  },

  container: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    marginTop: 10,
    marginBottom: 20,
    [theme.media.desktop]: {
      marginTop: 25,
      marginBottom: 45
    }
  },

  button: {
    minWidth: 437,
    marginTop: 55,
    [theme.media.mobile]: {
      minWidth: 240
    }
  }
}))
