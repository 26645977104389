import React, { useState, useContext } from 'react'
import PropTypes from 'prop-types'
import ReactImgix from 'react-imgix'
import classNames from 'classnames'

import { recipe } from '../../../constants'
import { useStyles } from './content-grid-item.styles'
import { UseItemAnimationContext } from '../../../hooks/use-item-animation'
import { HoverOverlay } from './hover-overlay'
import { SelectionOverlay } from './selection-overlay'
import animateStyle from './item-animation.css'

const ContentGridItem = ({
  imageUri,
  onSelected,
  isSelected,
  contentItemId
}) => {
  const [isItemHovered, setIsItemHovered] = useState(false)

  const [{ fadeOut }] = useContext(UseItemAnimationContext)

  const styles = useStyles()

  const { width } = recipe
  const height = width * recipe.ratio

  const cropToRatio = {
    ar: `1:${recipe.ratio}`,
    fit: 'crop'
  }

  const containerClasses = classNames(styles.container, {
    [animateStyle.fadeOut]: fadeOut && !isSelected
  })

  return (
    <div
      className={containerClasses}
      onMouseEnter={() => {
        !isSelected && setIsItemHovered(true)
      }}
      onMouseLeave={() => {
        !isSelected && setIsItemHovered(false)
      }}
      onClick={() => {
        onSelected(contentItemId)
      }}
    >
      <SelectionOverlay isSelected={isSelected} />
      <HoverOverlay isSelected={isSelected} isHovered={isItemHovered} />
      <ReactImgix
        src={imageUri}
        width={width}
        height={height}
        className={styles.imgix}
        imgixParams={cropToRatio}
      />
    </div>
  )
}

ContentGridItem.propTypes = {
  imageUri: PropTypes.string,
  onSelected: PropTypes.func,
  isSelected: PropTypes.bool,
  contentItemId: PropTypes.string
}

export default ContentGridItem
