import { createUseStyles } from 'react-jss'
import { commonStyles } from '../../../../shared/common.styles'

export const useStyles = createUseStyles(theme => ({
  ...commonStyles(theme),
  content: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: 50,
    paddingBottom: 200,
    [theme.media.mobile]: {
      paddingTop: 5,
      paddingBottom: 120
    }
  },

  profileList: {
    maxWidth: 770,
    maxHeight: 'max-content',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    flexFlow: 'row wrap'
  },

  button: {
    minWidth: 437,
    marginTop: 55,
    [theme.media.mobile]: {
      minWidth: 240
    }
  }
}))
