import React from 'react'

import NeonLogo from '../../../../../images/neon/neon-logo-hoz-wht-rgb.svg'
import { useStyles } from './header.styles'

/**
 * Renders a re-usable header with a neon logo and optional
 * login button.
 */
const Header = () => {
  const styles = useStyles()
  return (
    <div className={styles.header}>
      <img src={NeonLogo} alt="Neon Logo" className={styles.logo} />
    </div>
  )
}

export default Header
