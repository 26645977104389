import { createUseStyles } from 'react-jss'

export const useStyles = createUseStyles(theme => ({
  avatar: {
    width: 110,
    height: 110,
    [theme.media.mobile]: {
      width: 80,
      height: 80
    }
  },

  itemOverlay: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 120,
    height: 120,
    [theme.media.mobile]: {
      width: 90,
      height: 90,
      marginLeft: 5,
      marginRight: 5
    },
    borderRadius: '50%',
    '&:hover': {
      background: theme.neonGreen
    },
    marginLeft: 15,
    marginRight: 15
  },

  selected: {
    background: theme.neonGreen
  }
}))
