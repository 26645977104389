import React from 'react'
import { ThemeProvider } from 'react-jss'

import Layout from './components/layout'
import Router from './router'
import theme from '../../theme'

const ContentOnboardingModule = () => (
  <ThemeProvider theme={theme}>
    <Layout>
      <Router />
    </Layout>
  </ThemeProvider>
)

export default ContentOnboardingModule
